import { preloadPersonalSpaceDetail } from '@confluence/space-utils/entry-points';
import { preloadIntentBasedOnboarding } from '@confluence/home/entry-points/preloadIntentBasedOnboarding';
import {
	preloadHome,
	preloadHomeShell,
	preloadHomeMainContent,
} from '@confluence/home/entry-points/preloadHome';
import { preloadTemplatesCollectionEligible } from '@confluence/home/entry-points/preloadTemplatesCollectionEligible';
import { getPreloaderFnContext } from '@confluence/query-preloader-tools';
import { preloadPostOfficeConfluenceHomeBanner } from '@confluence/experiment-post-office-home-banner/entry-points/preloadConfuenceHomeBanner';
import { AccessStatus } from '@confluence/session-data';
import type { RouteMatch } from '@confluence/route';
import { isContentTypeEnabledInCurrentEnv } from '@confluence/content-types-utils';
import { expValEqualsNoExposure } from '@confluence/feature-experiments';
import { fg } from '@confluence/feature-gating';
import { SSRMeasures } from '@confluence/action-measures';

export const preloadHomeRoute = async (match: RouteMatch) => {
	const preloadedSessionContext = await getPreloaderFnContext();
	const { userId, cloudId, accessStatus } = preloadedSessionContext;

	const isDatabasesEnabled = isContentTypeEnabledInCurrentEnv('database');
	const isWhiteboardsEnabled = isContentTypeEnabledInCurrentEnv('whiteboard');

	const tasks: Promise<any>[] = [];

	const isLicensed =
		accessStatus === AccessStatus.LICENSED_ADMIN_ACCESS ||
		accessStatus === AccessStatus.LICENSED_USE_ACCESS;

	const canAccessHome =
		accessStatus !== 'ANONYMOUS_ACCESS' && accessStatus !== 'UNLICENSED_AUTHENTICATED_ACCESS';

	if (isLicensed && canAccessHome) {
		const options = {
			cloudId,
			isLicensed,
			userId,
			matchParams: match.params,
			isWhiteboardViewedEnabled: isWhiteboardsEnabled,
			isDatabaseEnabled: isDatabasesEnabled,
		};

		// eslint-disable-next-line check-react-ssr-usage/no-react-ssr
		if (process.env.REACT_SSR && fg('confluence_react_streaming')) {
			preloadHomeMainContent(options);
			tasks.push(preloadHomeShell(options));
		} else {
			tasks.push(preloadHome(options));
		}
	}

	tasks.push(preloadPersonalSpaceDetail());

	if (userId) {
		tasks.push(preloadIntentBasedOnboarding(), preloadTemplatesCollectionEligible());
	}

	const isLocaleEligible = fg('post-office_confluence-home-banner-v2-locale-check')
		? preloadedSessionContext.locale.toLowerCase().startsWith('en')
		: true;
	if (
		userId &&
		// Manually check locale in code, instead of using Statsig, because CCFE does not have locale populated with value during FG initialization yet.
		isLocaleEligible &&
		// suppress exposure because if feed is empty, users do not see the banner even if they are initially assigned to be in Variation
		expValEqualsNoExposure(
			'post_office_confluence_home_banner_experiment_-_v2',
			'cohort',
			'variation',
		)
	) {
		// Confluence Home Banner in SSR
		const preloadPostOfficeHome = async () => {
			return SSRMeasures.run(
				'ssr-app/prepare/preloadQuery/postOffice:ConfluenceHomeBanner',
				async () => {
					return preloadPostOfficeConfluenceHomeBanner(preloadedSessionContext);
				},
			);
		};

		tasks.push(preloadPostOfficeHome());
	}

	return Promise.all(tasks);
};
