import React from 'react';

import {
	PRODUCT_HOME_ID,
	COMPANY_HUB_SETTINGS_ID,
} from '@confluence/app-navigation/entry-points/navigationIDs';
import { CompanyHomeBuilderUpsellPage } from '@confluence/change-edition/entry-points/CompanyHomeBuilderUpsellPage';
import {
	CompanyHubSettingsPage,
	CompanyHubSettingsPageTabsType,
} from '@confluence/company-hub/entry-points/CompanyHubSettingsPage';
import { useCompanyHubPremiumGate } from '@confluence/company-hub-utils/entry-points/useCompanyHubPremiumGate';
import { fg } from '@confluence/feature-gating';
import { COMPANY_HUB_ANALYTICS } from '@confluence/named-routes';
import { getCompanyHubSpaceKey } from '@confluence/route-manager/entry-points/companyHubUtils';
import { getCompanyHubPseudoAppRoute } from '@confluence/ssr-app/entry-points/getCompanyHubPseudoAppRoute';

/**
 * The route "Company Hub settings > Analytics" in the Confluence app.
 * `CompanyHubAnalyticsRoute` wraps this one to implement the Company Hub pseudo app.
 */
function ConfluenceAppHubAnalyticsRoute() {
	const shouldShowPremiumUpsell = useCompanyHubPremiumGate();

	if (shouldShowPremiumUpsell) {
		return <CompanyHomeBuilderUpsellPage featureName="companyHub" />;
	}

	return <CompanyHubSettingsPage tab={CompanyHubSettingsPageTabsType.ANALYTICS} />;
}

ConfluenceAppHubAnalyticsRoute.NAVIGATION_PARAMS = () => {
	const spaceKey = getCompanyHubSpaceKey();
	const navView = fg('confluence_nav_4') ? COMPANY_HUB_SETTINGS_ID : PRODUCT_HOME_ID;

	return {
		Screen: {
			screenEvent: {
				name: 'companyHubAnalytics',
			},
			pageState: {
				routeName: COMPANY_HUB_ANALYTICS.name,
				spaceKey,
			},
		},
		MainLayout: {
			navView,
		},
	};
};

/**
 * The route "Company Hub settings > Analytics" in the Company Hub pseudo app if enabled; otherwise, in the Confluence app.
 */
export const CompanyHubAnalyticsRoute = getCompanyHubPseudoAppRoute(ConfluenceAppHubAnalyticsRoute);
